body {
  /* max-width: 1200px; */
  margin: 0 auto;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .container {
  width: 1200px;
  margin: 0 auto;
  height: 55vh;
} */

section {
  display: flex;
  margin: 0 auto;
  width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* button {
  width: 200px;
} */

button a {
  color: white;
}
.logout {
  cursor: pointer;
}
.logout:hover {
  color: orange;
}
